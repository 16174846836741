<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import ChangeDate from "@/components/changeDateComponent";

export default {
  page: {
    title: "งานอนุมัติ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, DatePicker, ChangeDate },
  data() {
    return {
      // tableData: tableData,

      title: "งานอนุมัติในสาขา",
      items: [
        {
          text: "งานอนุมัติ",
          active: true,
        },
        {
          text: "งานอนุมัติในสาขา",
          active: true,
        },
      ],
      loading: false,
      selected: "",
      rowsPage: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      optionSup: [],
      rowsRo: [],
      pageOptions: [10, 25, 50, 100],
      isHidden: false,
      branch: "",
      type: "",
      selectMode: "single",
      userBranchId: "",
      filter: {
        userBranchId: "",
        startDate: "",
        endDate: "",
      },
      optionBranch: [],
      userBranch: "",
      urlPrint: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "code",
          sortable: true,
          label: "รหัสขอนุมัติเดินทาง",
        },

        {
          key: "date",
          sortable: true,
          label: "วันที่ขอ",
        },
        {
          key: "createdBy",
          sortable: true,
          label: "ผู้ขออนุมัติ",
        },
        {
          key: "description",
          sortable: true,
          label: "รายละเอียด",
        },
        {
          key: "action",
          sortable: false,
          label: "",
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {},
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.optionBranch = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.totalRecord;
    this.getData();
    // this.getDataCreate();
  },
  created() {
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function () {
      this.overlayFlag = true;
      useNetw
        .get("api/branch-approval", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId != null
                ? this.filter.userBranchId.branchId
                : this.userBranchId,
            startDate: this.filter.startDate,
            endDate: this.filter.endDate,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecord = response.data.total;
          const per_Page = response.data.perPage;
          this.from = response.data.from;
          this.to = response.data.to;
          this.totalPage = Math.ceil(this.totalRecord / per_Page);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
          // this.isHidden = true;
        });
    },
    getPrint: function (id) {
      //  console.log(this.poId);
      this.id = id;
      this.loading = true;
      useNetw
        .get("api/branch-approval/print", {
          params: {
            formId: this.id,
          },
        })
        .then((response) => {
          this.dataPrint = response.data.url;
          window.location = this.dataPrint;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            this.$router.push("/login");
          } else {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response.data.message),
              appConfig.swal.type.error
            );
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rows) {
      this.selected = rows[0];
      this.alertConfirm(this.selected.roId);
    },
    alertConfirm(roId) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการออกใบแจ้งหนี้หรือไม่ !!?",
          // text: "ต้องการออกใบกำกับภาษีหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.confirmSubmitInvoice(roId);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    handleSearch() {
      this.getData();
    },
    // showRo() {
    //   this.getDataRo();
    // },
    getApprovePrint(apvId) {
      this.apvId = apvId;
      this.loading = true;
      useNetw
        .get("api/approve-vehicle-transfer/print", {
          params: {
            apvId: this.apvId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3">
                        ออกใบกำกับภาษี:
                        <multiselect
                          v-model="type"
                          label="receiptName"
                          type="search"
                          :options="receipt"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <!-- <div class="col-12 col-sm-6 col-md-4">
                      <div class="mb-3 position-relative">
                        สาขา:
                        <multiselect
                          v-model="filter.userBranchId"
                          label="nameTh"
                          type="search"
                          :options="optionBranch"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </div>
                    </div> -->
                    <div class="col-12 col-sm-6 col-md-2">
                      <div class="mb-3">
                        จากวันที่:
                        <date-picker
                          v-model="filter.startDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-2">
                      <div class="mb-3">
                        จากวันที่:
                        <date-picker
                          v-model="filter.endDate"
                          format="YYYY-MM-DD"
                          value-type="format"
                        ></date-picker>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-8">
                      <b-button
                        class="btn float-end m-1"
                        variant="info"
                        type="submit"
                        @click="handleSearch"
                      >
                        <i class="uil-search-alt"></i>
                        ค้นหา
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <b-button
                    class="btn float-end m-1"
                    variant="info"
                    type="submit"
                    @click="handleSearch"
                  >
                    <i class="uil-search-alt"></i>
                    ค้นหา
                  </b-button>
                
                </div>
              </div> -->
              <div class="row align-items-center">
                <div class="col-sm-12 col-md-3">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      แสดงผล&nbsp;
                      <b-form-select
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        @input="handlePageChange"
                      ></b-form-select
                      >&nbsp;รายการ
                    </label>
                  </div>
                </div>
              </div>
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <b-table
                    :items="rows"
                    :fields="fields"
                    :current-page="1"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    responsive="sm"
                  >
                    <template #cell(index)="rows">
                      {{ rows.index + 1 }}
                    </template>
                    <template #cell(date)="rows">
                      <changeDate :date="rows.item.date"></changeDate>
                    </template>
                    <template v-slot:cell(action)="rows">
                      <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                          <router-link
                            href="javascript:void(0);"
                            class="px-2 text-primary"
                            v-b-tooltip.hover
                            :to="{
                              name: 'detail-branch-approval',
                              params: { formId: rows.item.formIdBase64 },
                            }"
                            title="View"
                          >
                            <i class="uil uil-eye font-size-18"></i>
                          </router-link>
                        </li>
                        <a
                          class="px-2 text-success"
                          v-b-tooltip.hover
                          title="Print"
                          @click="getPrint(rows.item.formId)"
                        >
                          <i class="uil-print font-size-18"></i>
                        </a>
                      </ul>
                    </template>
                  </b-table>
                </div>
              </b-skeleton-wrapper>
              <div class="row">
                <span
                  v-if="this.totalRecord === 0"
                  class="text-center font-size-16"
                  >ไม่พบรายการ</span
                >
                <div class="col" v-if="this.totalRecord > 0">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.totalRecord }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRecord"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
